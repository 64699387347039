<template>
    <div class="beautys-detail-div page">
        <van-nav-bar class="nav-bar" :title="$t('concubine.concubine')" />
        <!-- <img class="banner-gif" src="/img/banner_gif.gif" alt=""> -->
    <van-tabs v-model="active" line-width="0" @change="tabsChange" >
                <van-tab :title="item.name" v-for="(item,index) in addlist" :key="index">
                </van-tab>
            </van-tabs>
        <div class="beauty-body">

            
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
        :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <van-list class="hot-xuanfei" v-model="loading" :finished="finished" :offset="50" :immediate-check="false"
          :finished-text="$t('video.no_more')" @load="onLoad">

            <div class="beauty-content-div">
                        <div class="beauty-item-div" v-for="(item,index) in datalist" :key="index" @click="toPlayVideo(item.id)">
                            <div class="bg-img-div" ><img
                                    :src="item.img_url"
                                    >
                                <div class="beauty-item-bottom-div" >
                                    <div ><van-icon color="#fff" name="location-o" /><span >{{item.address}}</span></div>
                                    <!-- <div >{{$t('xuanfei.DateHer')}}</div> -->
                                </div>
                            </div>
                            <div class="describe-div" >
                                <ul style="display: flex;">
                                    <li ><span >{{ item.xuanfei_name }}</span></li>
                                    <li v-if="item.service_data.length > 0">
                                        <div><i ></i>
                                            <span>
                                                >{{item.service_data[0]}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
        </van-list>

      </van-pull-refresh>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: 0,
            addlist: [],
            datalist:[],
            isLoading: true,
      loading: false,
      finished: false,
      page:1,
      count:0,
            active_id:''
        };
    },
    methods: {
        toPlayVideo(id) {

        if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
            this.$router.push({ path: '/Login' })
        } else {
            if (id) {
            this.$router.push({ path: '/nHomeDetail?id=' + id })
            }
        }
        },
        getAddress() {
            this.$http({
                method: 'get',
                url: 'address_list'
            }).then(res => {
                this.addlist = res.data;
            this.active_id = res.data[0].id
                this.getxuanfeilist()
            });
        },
        tabsChange(e){
            this.datalist = []
            this.active_id = this.addlist[e].id
            this.getxuanfeilist()
        },
        onRefresh() {
        setTimeout(() => {
            this.page = 1
            // this.datalist = []
            this.getxuanfeilist();
            this.isLoading = false;
            this.$toast(this.$t('reservation.refresh'));
        }, 500);
        },
        onLoad() {
        this.loading = true;
        this.getxuanfeilist();
        let timer = setTimeout(() => {
            if (this.refreshing) {
            this.datalist = [];
            this.refreshing = false;
            }
            // this.loading = false;
            // if (this.datalist.length === this.count) {
            // this.finished = true;
            // }
            this.finished && clearTimeout(timer);//清除计时器
        }, 500);
        },
		getxuanfeilist() {
            
            this.$http({
                method: 'get',
                url: 'xuanfeilist',
                data: { page: this.page,id:this.active_id },
            }).then(res => {
                console.log(res.data)
                this.isLoading = false;
                this.loading = false;
                if (this.page == 1) {
                this.datalist = res.data;
                } else {

                this.datalist = this.datalist.concat(res.data);
                }
                if (res.data.length == 0) {
                this.finished = true;
                } else {
                this.finished = false;
                }
                this.count = res.data.count;
                this.page++;
            })
		}
    },
    created() {
        this.getAddress();
    }
};
</script>

<style lang="less" scoped>
.page {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f5;
}

.nav-bar {
    // background: linear-gradient(90deg, #775fd9, #c24491);
    height: 100px;
    background: url('../../assets/topbar_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.van-nav-bar {
    line-height: 50px;
}

::v-deep .van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #ffffff;
    font-size: 35px;
}

::v-deep .van-nav-bar__content {
    height: 100px;
}

.beautys-detail-div .banner-gif {
    display: block;
    border-radius: 30px;
    width: calc(100% - 30px);
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 20px auto;
}


::v-deep .van-tabs__nav {
    //   background: linear-gradient(90deg, #775fd9, #c24491);
}

::v-deep .van-tab {
    //   color: #ffffff;
    font-size: 30px;
    line-height: 1;
}

::v-deep .van-tabs__line {
    bottom: 15px;
    width: 55px;
    height: 7px;
    border-radius: 0px;
    background-color: #ffffff;
}

::v-deep .van-tabs--line .van-tabs__wrap {
    height: 70px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
    padding: 0 23px;
}



.beauty-body {
    width: 100%;
    background-color: #fff;
    flex: 1;
    height: 100%;
    padding-bottom: 80px;
}


.beauty-body .beauty-content-div {
    width: 100%;
    height: 100%;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between
}

.beauty-body .beauty-content-div .beauty-item-div {
    width: 49%
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div {
    height: 360px;
    background-size: cover;
    background-position: 50%;
    position: relative;
    border-radius: 10px
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div .beauty-item-bottom-div {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0;
    background: rgba(0, 0, 0, .4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div .beauty-item-bottom-div>div {
    display: flex;
    align-items: center;
    justify-content: center
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div .beauty-item-bottom-div>div:first-child>img {
    height: 28px;
    margin-right: 10px
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div .beauty-item-bottom-div>div:first-child>span {
    font-size: 28px;
    color: #fff
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div .beauty-item-bottom-div>div:nth-child(2) {
    height: 50px;
    border: 1px solid #fff;
    border-radius: 8px;
    font-size: 28px;
    color: #fff;
    padding: 0 10px
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div {
    width: 100%;
    margin: 10px 0 20px
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div ul {
    width: 100%;
    height: 100%
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div ul li {
    width: 100%;
    height:40px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div ul li span {
    font-size: 28px;
    color: #666
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div ul>li:nth-child(2)>div:first-child {
    display: flex;
    align-items: center;
    justify-content: center
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div ul>li:nth-child(2)>div:first-child i {
    display: inline-block;
    margin-right: 8px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #efba8f
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div ul>li:nth-child(2)>div:nth-child(2) i {
    color: #dc63a1;
    margin-right: 8px
}

.beauty-body .beauty-content-div .beauty-item-div:first-child {
    margin-top: 0 !important
}

.beauty-body .beauty-content-div .beauty-item-div:nth-child(odd) {
    // margin-top: -40px
}

.beauty-body .beauty-content-div .beauty-item-div:nth-child(odd) .bg-img-div {
    height: 450px
}

.beauty-body .process-btn {
    background: linear-gradient(90deg, #8e4ff1, #c571cf);
    width: 100%;
    color: #fff;
    font-size: 28px;
    font-weight: 700
}

.beauty-body {
    width: 100%;
    background-color: #fff;
    flex: 1
}

.beauty-body .van-popup {
    background-color: #f7e5f0
}

.beauty-body .van-tabs__nav--card {
    height: 40px;
    border: 0!important;
    margin: 0
}

.beauty-body .van-tabs__nav--card .van-tab {
    margin: auto 10px auto 0;
    border: 0;
    height: 100%;
    padding: 0 28px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #646566
}

.beauty-body .van-tabs__nav--card .van-tab--active {
    background-color: #dedede;
    color: #000
}

.beauty-body .beauty-content-div {
    width: 100%;
    height: 100%;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between
}

.beauty-body .beauty-content-div .beauty-item-div {
    width: 49%
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div {
    // height: 440px;
    background-size: cover;
    background-position: 50%;
    position: relative;
    border-radius: 10px
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div .beauty-item-bottom-div {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0;
    background: rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div .beauty-item-bottom-div>div {
    display: flex;
    align-items: center;
    justify-content: center
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div .beauty-item-bottom-div>div:first-child>img {
    height: 28px;
    margin-right: 10px
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div .beauty-item-bottom-div>div:first-child>span {
    font-size: 28px;
    color: #fff
}

.beauty-body .beauty-content-div .beauty-item-div .bg-img-div .beauty-item-bottom-div>div:nth-child(2) {
    height: 50px;
    border: 1px solid #fff;
    border-radius: 8px;
    font-size: 28px;
    color: #fff;
    padding: 0 10px
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div {
    width: 100%;
    margin: 10px 0 20px
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div ul {
    width: 100%;
    height: 100%
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div ul>li:nth-child(2)>div:first-child {
    display: flex;
    align-items: center;
    justify-content: center
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div ul>li:nth-child(2)>div:first-child i {
    display: inline-block;
    margin-right: 8px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #efba8f
}

.beauty-body .beauty-content-div .beauty-item-div .describe-div ul>li:nth-child(2)>div:nth-child(2) i {
    color: #dc63a1;
    margin-right: 8px
}

.beauty-body .beauty-content-div .beauty-item-div:first-child {
    margin-top: 0!important
}

.beauty-body .beauty-content-div .beauty-item-div:nth-child(odd) {
    // margin-top: -80px
}

.beauty-body .beauty-content-div .beauty-item-div:nth-child(odd) .bg-img-div {
    height: 360px
}

.beauty-body .process-btn {
    background: linear-gradient(90deg,#8e4ff1,#c571cf);
    width: 100%;
    color: #fff;
    font-size: 28px;
    font-weight: 700
}
</style>
<style>
.beauty-body .van-popup {
    background-color: #f7e5f0
}

.beauty-body .van-tab--active {
    background-color: #dedede;
    color: #000
}

.beauty-body {
    height: 40px;
    border: 0 !important;
    margin: 0
}

.beauty-body .van-tab {
    margin: auto 10px auto 0;
    border: 0;
    height: 100%;
    padding: 0 28px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #646566
}
</style>